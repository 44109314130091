import { Injectable, inject } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ApiService } from '@app/shared/services/api.service';
import { tap } from 'rxjs/operators';

export interface UserStateModel {
  user: any;
  initials: string;
  dashboards: string[];
  roles: string[];
  attributes: any;
  fullName: string;
}

export class GetUserDetails {
  static readonly type = '[User] Get Details';
}

export class LogoutUser {
  static readonly type = '[User] Logout';
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    initials: '',
    dashboards: [],
    roles: [],
    attributes: {},
    fullName: ''
  }
})
@Injectable({ providedIn: 'root' })
export class UserState {
  private api = inject(ApiService);

  @Action(GetUserDetails)
  getUserDetails(ctx: StateContext<UserStateModel>) {
    const authStorage = localStorage.getItem('auth');
    if (!authStorage) {
      // If no auth token exists, clear the state (optional)
      ctx.setState({
        user: null,
        initials: '',
        dashboards: [],
        roles: [],
        attributes: {},
        fullName: ''
      });
      return;
    }
    const parsedUser = JSON.parse(authStorage);

    return this.api.get(`api/users/${parsedUser.sub}`).pipe(
      tap((response: any) => {
        const attributes = response.attribute?.attributes || {};
        // Extract initials from the "short" array if available
        const initials =
          attributes.short && attributes.short.length > 0
            ? attributes.short[0]
            : '';
        const dashboards = attributes.dashboards || [];
        const roles = response.roles || [];
        const fullName = `${response.firstName} ${response.lastName}`;

        // Optionally, update any additional localStorage keys
        localStorage.setItem('userLogedInUsername', JSON.stringify(initials));

        // Update the state with the fetched user details
        ctx.patchState({
          user: response,
          initials,
          dashboards,
          roles,
          attributes,
          fullName
        });
      })
    );
  }

  @Action(LogoutUser)
  logout(ctx: StateContext<UserStateModel>) {
    // Remove any relevant local storage items
    localStorage.removeItem('auth');
    localStorage.removeItem('userLogedInUsername');
    
    // Reset the state to its default values
    ctx.setState({
      user: null,
      initials: '',
      dashboards: [],
      roles: [],
      attributes: {},
      fullName: ''
    });
  }

  // Selectors to retrieve parts of the state

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static getInitials(state: UserStateModel) {
    return state.initials;
  }

  @Selector()
  static getDashboards(state: UserStateModel) {
    return state.dashboards;
  }

  @Selector()
  static getRoles(state: UserStateModel) {
    return state.roles;
  }

  @Selector()
  static getAttributes(state: UserStateModel) {
    return state.attributes;
  }

  @Selector()
  static getFullName(state: UserStateModel) {
    return state.fullName;
  }
}
