import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // core URL's
  private readonly avCoreUrl: string = environment.avCore;
  private readonly avImportUrl: string = environment.avImport;

  constructor(private httpClient: HttpClient) {}

  private buildHttpOptions(
    headers?: HttpHeaders | { [header: string]: string | string[] }
  ) {
    return { headers };
  }

  // get request
  get(
    url: string,
    params?: Record<string, any>,
    headers?: HttpHeaders
  ): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params || {} });
    const options = this.buildHttpOptions(headers);

    return this.httpClient
      .get<any>(`${this.avCoreUrl}/${url}`, { params: httpParams, ...options })
      .pipe(map((response) => response));
  }
  
  post(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    const options = this.buildHttpOptions(headers);

    return this.httpClient
      .post<any>(`${this.avCoreUrl}/${url}`, data, options)
      .pipe(map((response) => response));
  }

  postImport(
    url: string,
    data: any,
    headers?: HttpHeaders
  ): Observable<string> {
    const options = this.buildHttpOptions(headers);

    return this.httpClient
      .post<any>(`${this.avImportUrl}/${url}`, data, options)
      .pipe(map((response) => response));
  }

  put(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    const options = this.buildHttpOptions(headers); 
  
    return this.httpClient
      .put<any>(`${this.avCoreUrl}/${url}`, data, options)
      .pipe(map((response) => JSON.stringify(response)));
  }

  delete(url: string, headers?: HttpHeaders): Observable<string> {
    const options = this.buildHttpOptions(headers);

    return this.httpClient
      .delete<any>(`${this.avCoreUrl}/${url}`, options)
      .pipe(map((response) => response));
  }
}