import { Routes } from '@angular/router';
import { SvGuardRoute } from './sv.guard';

export const SvRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.SVDashboardComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'my-profile',
    loadComponent: () =>
      import('./my-profile/my-profile.component').then(
        (c) => c.MyProfileComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'gua/:id',
    loadComponent: () =>
      import('./gua-task/detail/gua-detail.component').then(
        (c) => c.GuaDetailComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'view/:id',
    loadComponent: () =>
      import('../../components/view-order/view-order.component').then(
        (c) => c.ViewOrderComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'edit/:id',
    loadComponent: () =>
      import('../../components/edit-order/edit-order.component').then(
        (c) => c.EditOrderComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'task/edit/:id',
    loadComponent: () =>
      import('@app/components/tasklist/edit-task/edit-task.component').then(
        (c) => c.EditTaskComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'technical-news/edit/:id',
    loadComponent: () =>
      import('./technical-news/edit-news/edit-news.component').then(
        (c) => c.EditNewsComponent
      ),
    canMatch: [SvGuardRoute],
  },
  {
    path: 'sb-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sb/dashboard/dashboard.component').then(
            (c) => c.SBDashboardComponent
          ),
      },
      {
        path: 'new-order',
        loadComponent: () =>
          import('../sb/order/create-order/create-order.component').then(
            (c) => c.CreateOrderComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
        //
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
        //
      },
    ],
  },
  {
    path: 'sv-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv/dashboard/dashboard.component').then(
            (c) => c.SVDashboardComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
      },
    ],
  },
  {
    path: 'einteiler-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv-enteiler/dashboard/dashboard.component').then(
            (c) => c.EnteilerDashComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
      },
    ],
  },
  {
    path: 'sv-einteiler-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv-enteiler/dashboard/dashboard.component').then(
            (c) => c.EnteilerDashComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
      },
    ],
  },
];
