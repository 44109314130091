import { Component, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../auth.service';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { AutoFocusModule } from 'primeng/autofocus';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    AutoFocusModule
  ],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  showPws: boolean = false;
  submited = signal<boolean>(false);
  errors: any = [];

  constructor(private fb: FormBuilder, private auth: AuthService) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  hasError(control: any, error: string) {
    const { dirty, touched, errors } = control;
    return dirty && touched && errors && errors[error];
  }

  onLogin() {
    this.submited.set(true);

    const { username, password } = this.loginForm.controls;
    this.auth.login(username.value, password.value);

    this.auth.loginSuccess$.subscribe((response) => {
      if (response === true) {
        this.submited.set(false);
      }
    });

    this.auth.loginError$.subscribe((errorMessage) => {
      this.errors = errorMessage;
      this.submited.set(false);
    });
  }

  getErrorMessage() {
    if (this.loginForm.controls['username'].hasError('required')) {
      return 'Benutzername ist erforderlich';
    }
    if (this.loginForm.controls['password'].hasError('required')) {
      return 'Passwort ist erforderlich';
    }
    return 'Ungültiger Benutzername oder Passwort';
  }

  onShowPassword() {
    this.showPws = !this.showPws;
  }
}
